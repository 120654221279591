/* eslint-disable no-unreachable */
import { globalObject } from "./globalObject.js";

export function loadOptionalLibrary(name, globalName) {
  globalName = globalName || name;
  if (globalObject[globalName]) {
    return Promise.resolve(globalObject[globalName]);
  }

  return import(name);

}
